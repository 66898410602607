@import "../../common.scss";

.footer_sec1 {
  // padding-top: 4.986875em;
  padding-top: 4.375em;
  position: relative;
  .copy_right_wrapper {
    // border-top: 2px solid #5e61e24d;
    border-top: 1px solid #5E61E2;
    padding: 1.75em 0;
    .copy_right {
      color: #1c1c1c;
      font-size: 0.9375em;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 1.533;
      text-align: center;
      .copyright_link {
        color: inherit;
      }
    }
  }
  .scroll_up {
    width: 100%;
    max-width: 3.75em;
    object-fit: contain;
    position: absolute;
    left: 50%;
    top: -20%;
    transform: translateX(-50%);
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .footer_sec1 {
    padding: 3.2em 1px 0;
    .scroll_up {
      left: initial;
      right: -0.8%;
      top: -13%;
      max-width: 3.8em;
    }
    .footer_content_wrapper {
      text-align: center;
      .text {
        color: #1C1C1C;
        font-size: 1.4em;
        font-weight: 400;
        font-family: "Montserrat";
        letter-spacing: 0px;
        line-height: 1.285;
        text-align: center;
        padding-bottom: 2.285714285714286em;
        display: inline-block;
      }
    }
   .copy_right_wrapper {
    border-top: 1px solid #6F00FF;
    padding: 2em 0;
    .copy_right {
      font-size: 1.2em;
      line-height: 1.5;
      padding: 0 5em;
    }
   }
  }
}
