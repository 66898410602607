@import "../../common.scss";

// .before_after_sec1 {
//   //   display: grid;
//   //   place-content: center;
//   display: flex;
//   justify-content: center;
//   padding: 0;
//   width: 100%;
//   max-width: 100%;
//   position: relative;
//   overflow: hidden;
//   border-radius: 1em;
//   --position: 50%;
//   box-shadow: -2px 3px 6px #0000002b;
//   img {
//     display: block;
//     max-width: 100%;
//   }

//   // main {
//   //   display: grid;
//   //   place-items: center;
//   //   min-height: 100vh;
//   // }

//   .image_container {
//     width: 100%;
//     max-width: 100%;
//     //   max-width: 800px;
//     //   max-height: 90vh;
//     //   aspect-ratio: 1/1;
//     .slider_image {
//       width: 100%;
//       max-width: 100%;
//       height: 100%;
//       object-fit: cover;
//       object-position: right;
//     }

//     .image_before {
//       position: absolute;
//       inset: 0;
//       width: var(--position);
//       filter: grayscale(100%);
//     }
//     .before_text_wrapper {
//       position: absolute;
//       bottom: 5%;
//       left: 5%;
//     }
//     .after_text_wrapper {
//       position: absolute;
//       bottom: 5%;
//       right: 5%;
//     }
//     .title {
//         color: #000000;
//     }
//     .desc {
//         color: #000000;
//     }
//   }

//   .slider {
//     position: absolute;
//     inset: 0;
//     cursor: pointer;
//     opacity: 0;
//     /* for Firefox */
//     width: 100%;
//     max-width: 100%;
//     height: 100%;
//   }

//   .slider:focus-visible ~ .slider-button {
//     outline: 5px solid black;
//     outline-offset: 3px;
//   }

//   .slider_line {
//     position: absolute;
//     inset: 0;
//     //   width: .2rem;
//     width: 100%;
//     max-width: 0.75%;
//     height: 100%;
//     background-color: #000000;
//     /* z-index: 10; */
//     left: var(--position);
//     transform: translateX(-50%);
//     pointer-events: none;
//   }

//   .slider_button {
//     position: absolute;
//     background-color: transparent;
//     color: black;
//     padding: 0.5rem;
//     border-radius: 100vw;
//     display: grid;
//     place-items: center;
//     top: 50%;
//     left: var(--position);
//     transform: translate(-50%, -50%) rotate(-90deg);
//     pointer-events: none;
//     /* z-index: 100; */
//     // box-shadow: 1px 1px 1px hsl(0, 50%, 2%, 0.5);
//     width: 100%;
//     max-width: 6em;
//   }
// }

.before_after_sec {
  width: 100%;
  max-width: 100%;
  height: 100%;
  // height: 40.625em;
  overflow: hidden;
  margin-top: 3.2em;
  box-shadow: -2px 3px 6px rgba(0,0,0,.169);
  // div.__rcs-handle-button {
  //   background: transparent url("../../source/images/general/range_arrow.png") no-repeat !important;
  //   background-size: 100% 100%;
  //   width: 11.125em !important;
  //   height: 5.625em !important;
  //   object-fit: contain;
  //   // transform: rotate(90deg);
  //   border-radius: 0 !important;
  //   border-width: 0 !important;
  //   box-shadow: none !important;
  //   backdrop-filter: none !important;
  // }
  // div.__rcs-handle-arrow {
  //   display: none;
  // }
  // div.__rcs-handle-line {
  //   width: 0.6875em !important;
  //   background-color: #000 !important;
  // }
}


@media(max-width: 767px) {
  .before_after_sec {
    height: 100%;
    // div.__rcs-handle-button {
    //   background: transparent url("../../source/images/general/mb_range_arrow_2.png") no-repeat !important;
    //   width: 4.6em !important;
    //   height: 9.2em !important;
    //   rotate: 90deg;
    // }
  }
}