@import "../../common.scss";

.commingsoon_sec1 {
  background-color: #1c1c1c;
  height: 100vh;
  overflow-y: auto;
  //   padding-bottom: 5.8125em;
  .commingsoon_contianer {
    padding: 0 6.25em;
  }
  .logo_wrapper {
    background-color: #ffffff;
    width: 100%;
    max-width: 100%;
    padding: 2.075em 0;
    .logo_img {
      width: 100%;
      max-width: 17.048125em;
    }
  }
  .commingsoon_row {
    position: relative;
    margin: 0 auto;
    padding-bottom: 5.8125em;
    .commingsoon_col {
      padding: 0;
    }

    .commingsoon_text_wrapper {
      position: absolute;
      left: initial;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .text {
        color: #ffffff;
        font-size: 4.125em;
        font-family: "Poppins";
        font-weight: 400;
        letter-spacing: 0px;
        // line-height: 0.636;
        line-height: normal;
        margin-bottom: 0.0454545454545455em;
      }
      .heading {
        color: #ffffff;
        font-size: 13.3125em;
        font-family: "Poppins";
        font-weight: bold;
        letter-spacing: 0px;
        // line-height: 4.014;
        line-height: 1.2;
      }
    }

    .image_wrapper {
      width: 100%;
      max-width: 100%;
      height: 78.25vh;
      .comminsoon_img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media (min-width: 2560px) {
  .commingsoon_contianer {
    padding: 0 10.25em;
  }
}

@media (max-width: 1920px) {
  .commingsoon_contianer {
    padding: 0 6.25em;
  }
}

@media (max-width: 835px) {
  .commingsoon_sec1 {
    .commingsoon_row {
      padding: 5em 0 3em;
      .commingsoon_text_wrapper {
        position: relative;
        top: initial;
        left: initial;
        align-items: center;
        text-align: center;
        .heading {
          font-size: 10.3125em;
        }
      }
      .image_wrapper {
        height: 100%;
        margin-top: 2.5em;
        height: 50vh;
        .comminsoon_img {
          object-fit: contain;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .commingsoon_sec1 {
    .commingsoon_row {
      .commingsoon_text_wrapper {
        .heading {
          font-size: 8.3125em;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .commingsoon_sec1 {
    &::-webkit-scrollbar {
        width: 6px;
        background-color: #545353;
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $color-ffffff;
        display: none;
      }
    .logo_wrapper {
      padding: 1.234em 0;
      text-align: center;
      .logo_img {
        max-width: 12.885em;
      }
    }
    .commingsoon_row {
      padding: 6.9em 0 8.9em;
      .image_wrapper {
        margin-top: 2.1em;
        height: 100%;
      }
      .commingsoon_text_wrapper {
        .text {
          font-size: 2.5em;
          line-height: 1.28;
          margin-bottom: 0;
        }
        .heading {
          font-size: 6em;
          //   line-height: 2.7;
          line-height: 1.25;
        }
      }
    }
  }
}
