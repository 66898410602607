@import "../../common.scss";

.thank_sec1 {
  background-color: #1c1c1c;
  height: 100vh;
  padding: 7.5em 6.25em 4.1875em;

  .thank_row {
    margin: 0 auto;
  }
  .thank_col {
    padding: 0;
    align-self: center;
  }
  .thank_content_wrapper {
    position: relative;
    z-index: 2;
    .thank_heading {
      color: #ffffff;
      font-size: 13.3125em;
      font-weight: bold;
      font-family: "Poppins";
      letter-spacing: 0px;
      //   line-height: 4.02;
      line-height: 1.1;
      text-transform: uppercase;
    }
    .thank_desc {
      color: #ffffff;
      font-size: 1.625em;
      font-weight: 500;
      font-family: "Poppins";
      letter-spacing: 0px;
      line-height: 1.538;
      padding-right: 9.5em;
      margin-top: 1em;
    }
    .thank_cta_wrapper {
      margin-top: 5em;
      .thank_cta {
        color: #6f00ff;
        font-size: 1.75em;
        font-weight: 600;
        font-family: "Poppins";
        letter-spacing: 0px;
        line-height: 1.428;
        border-bottom: 3px solid #6f00ff;
        padding-bottom: 0.2857142857142857em;
      }
    }
  }
  .thank_img_wrapper {
    width: 100%;
    // max-width: 100%;
    max-width: 99.37%;
    height: calc(100vh - 11.6875em);
    float: right;
    .thank_img {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .thank_sec1 {
    padding: 7.5em 3.25em 4.1875em;
    .thank_content_wrapper {
      .thank_desc {
        padding-right: 6.5em;
      }
    }
  }
}

@media (max-width: 991px) {
  .thank_sec1 {
    height: 100%;
    padding: 7.5em 0 4.1875em;
    .thank_content_wrapper {
      padding: 4em 3.25em;
      .thank_heading {
        text-align: center;
      }
      .thank_desc {
        padding-right: 0;
        text-align: center;
      }
      .thank_cta_wrapper {
        text-align: center;
      }
    }
    .thank_img_wrapper {
      max-width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 767px) {
  .thank_sec1 {
    padding: 5em 0 3.1em;
    .thank_content_wrapper {
        padding: 3.2em 3em 2.8em;
      .thank_heading {
        font-size: 6em;
        line-height: 1.4;
      }
      .thank_desc {
        font-size: 1.8em;
        line-height: 1.78;
        margin-top: 0.1111111111111111em;
      }
      .thank_cta_wrapper {
        margin-top: 2.4em;
        .thank_cta {
          border-bottom: 2px solid #6f00ff;
          font-size: 2em;
          line-height: 2;
          padding-bottom: 0.25em;
        }
      }
    }
  }
}
