@import "../../common.scss";

.home_sec1 {
  //   height: 100vh;
  overflow: hidden;
  .banner_wrapper {
    position: relative;
  }
  .banner_video {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    // object-fit: cover;
    object-fit: fill;
    .video_source {
      width: 100%;
      max-width: 100%;
      //   height: 100vh;
      //   min-height: 100vh;
    }
  }
  .play_button {
    width: 100%;
    max-width: 7.58625em;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.home_sec2 {
  padding: 9.375em 0 9.375em;
  .prototype_wrapper {
    text-align: center;
    .desc {
      margin-top: 1.230769230769231em;
      padding: 0 5em;
    }
  }
  .prototype_slider_wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    .prototype_row {
      margin: 5.875em auto 0;
      padding: 0 6em;
    }
    .prototype_col {
      padding: 0;
      align-self: center;
      //   &:nth-child(2) {
      //     padding-right: 3.75em;
      //   }
      //   &:nth-child(1) {
      //     padding-left: 7.5em;
      //   }
    }
    .img_wrapper {
      width: 100%;
      max-width: 100%;
      height: 100%;
      .protpype_img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 43.625em;
        object-fit: contain;
      }
    }
    .prototype_content_wrapper {
      // position: relative;
      height: 100%;
      padding-left: 7.5em;
      .number {
        color: #1c1c1c;
        // font-size: 6.25em;
        font-size: 3.571428571428571em;
        font-weight: bold;
        letter-spacing: 0px;
        line-height: 0.8;
        opacity: 0.1;
        position: absolute;
        // top: -24%;
        top: -76%;
        // left: initial;
        left: 0;
      }
      .title {
        position: relative;
        color: #543787;
        font-size: 1.75em;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: 1.428;
        padding-left: 2.95em;
      }
      .desc {
        color: #1c1c1c;
        font-size: 1.625em;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.385;
        opacity: 0.6;
        margin-top: 1.038461538461538em;
      }
    }
  }
  .prototype_arrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 2.5em;
    right: 2.5em;
    .prototype_prev,
    .prototype_next {
      width: 100%;
      max-width: 6.25em;
      object-fit: contain;
      z-index: 1;
      cursor: pointer;
    }
  }
}

.home_sec3 {
  // height: 100vh;
  height: 67.5em;
  overflow: hidden;
  .innovation_wrapper {
    position: relative;
  }
  .innovation_video {
    width: 100%;
    max-width: 100%;
    // height: 100%;
    height: 67.5em;
    // object-fit: cover;
    object-fit: fill;
    .video_source {
      width: 100%;
      max-width: 100%;
      // height: 100%;
      // min-height: 67.5em;
      // max-width: 67.5em;
    }
  }
  .innovation_button {
    width: 100%;
    max-width: 7.58625em;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // cursor: pointer;
  }
  .video_banner {
    width: 100%;
    max-width: 100%;
  }
}

.home_sec4 {
  // padding: 9.375em 0;
  padding: 9.375em 0 0;
  .heading {
    text-align: center;
  }
  .tab_conatiner {
    display: flex;
    justify-content: center;
    align-self: center;
    gap: 9.375em;
    margin-top: 2.5em;
    .tab_name {
      border-bottom: 3px solid transparent;
      color: #1c1c1c;
      font-size: 2em;
      font-weight: 600;
      letter-spacing: 0px;
      line-height: 1.25;
      width: 100%;
      max-width: fit-content;
      padding-bottom: 0.348125em;
      opacity: 0.3;
      cursor: pointer;
      &.active {
        border-bottom: 3px solid #5e61e2;
        opacity: 1;
      }
    }
  }
  .tab_slider_container {
    position: relative;
    margin-top: 3.74125em;
    .product_img_wrapper {
      width: 100%;
      max-width: 100%;
    }
    .product_img {
      width: 100%;
      max-width: 100%;
      display: block;
    }
    .product_arrow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 2.4em;
      right: 2.4em;
      .product_prev,
      .product_next {
        width: 100%;
        max-width: 6.25em;
        object-fit: contain;
        z-index: 1;
        cursor: pointer;
      }
    }
  }
}

.home_sec5 {
  // padding-bottom: 9.375em;
  padding: 9.375em 0 9.375em;
  .shelf_text_container {
    text-align: center;
    .desc {
      margin-top: 1.230769230769231em;
      padding: 0 5.25em;
    }
  }
  .shelf_img_container {
    margin-top: 3.75em;
    .shelf_img {
      width: 100%;
      max-width: 100%;
    }
  }
}

.fslightbox_container {
  .fslightbox-fade-in-strong {
    -webkit-animation: none;
    animation: none;
  }
}

@media (max-width: 1620px) {
  .home_sec4 {
    .tab_slider_container {
      .product_arrow {
        left: 1.6em;
        right: 1.6em;
      }
    }
  }
}

@media (max-width: 1367px) {
  .home_sec4 {
    .tab_slider_container {
      .product_arrow {
        left: 1em;
        right: 1em;
      }
    }
  }
}

@media (max-width: 835px) {
  .home_sec1 {
    .banner_video {
      height: 100%;
    }
  }

  .home_sec2 {
    .prototype_wrapper {
      .desc {
        padding: 0;
      }
    }
    .prototype_slider_wrapper {
      .prototype_content_wrapper {
        padding-left: 0;
        text-align: center;
        margin-top: 6em;
        .title {
          display: inline-block;
          padding-left: 0;
        }
        .number {
          left: -30%;
        }
      }
    }
    .prototype_arrow {
      position: relative;
      top: initial;
      bottom: initial;
      left: initial;
      right: initial;
      justify-content: center;
      margin-top: 4em;
      gap: 2.6em;
    }
  }

  .home_sec3 {
    height: 100%;
  }
}

@media (max-width: 767px) {
  .home_sec1 {
    margin-top: 5em;
    .banner_video {
      height: 100%;
    }
  }

  .home_sec2 {
    padding: 6.05em 1px 6em;
    .prototype_wrapper {
      .desc {
        margin-top: 1.375em;
      }
    }
    .prototype_slider_wrapper {
      .prototype_row {
        padding: 0;
        margin: 3.2em auto 0;
      }
      .img_wrapper {
        padding: 0 2.75em;
        .protpype_img {
          height: 100%;
          max-height: 27.8em;
        }
      }
      .prototype_content_wrapper {
        margin-top: 4em;
        .title {
          font-size: 2em;
        }
        .number {
          font-size: 2.5em;
          top: -23%;
          left: -18.5%;
        }
        .desc {
          font-size: 1.6em;
          line-height: 1.625;
          margin-top: 1em;
        }
      }
    }
    .prototype_arrow {
      margin-top: 2.8em;
      gap: 1.6em;
      .prototype_prev,
      .prototype_next {
        max-width: 3.8em;
      }
    }
  }

  .home_sec3 {
    height: 100%;
    .innovation_video {
      height: 100%;
    }
  }

  .home_sec4 {
    // padding: 6.006em 1px 6em;
    padding: 6.006em 1px 0;
    .tab_conatiner {
      // justify-content: space-between;
      gap: 5%;
      margin-top: 2.2em;
      .tab_name {
        font-size: 2em;
        padding-bottom: 0.348125em;
      }
    }
    .tab_slider_container {
      margin-top: 3.2em;
      .product_arrow {
        justify-content: center;
        gap: 1.6em;
        position: relative;
        top: initial;
        bottom: initial;
        left: initial;
        right: initial;
        margin-top: 2em;
        .product_prev,
        .product_next {
          max-width: 3.8em;
        }
      }
    }
  }

  .home_sec5 {
    // padding: 0 1px 6.4em 1px;
    padding: 6em 1px 6.4em 1px;
    .shelf_text_container {
      .desc {
        padding: 0;
      }
    }
  }
}

@media (max-width: 375px) {
  .home_sec4 {
    .tab_conatiner {
      .tab_name {
        font-size: 1.8em;
      }
    }
  }
}

@media (max-width: 341px) {
  .home_sec4 {
    .tab_conatiner {
      .tab_name {
        font-size: 1.6em;
      }
    }
  }
}
