@import "./common-mixins.scss";

$color-ffffff: #ffffff;
$color-EE4E56: #ee4e56;
$color-F3F3F3: #f3f3f3;
$color-212529: #212529;
$color-707070: #707070;
$color-131313: #131313;
$color-9A9A9A: #9a9a9a;
$color-DDDDDD: #dddddd;
$color-FFAEB2: #ffaeb2;
$color-626262: #626262;

body {
  font-family: "Poppins", sans-serif;
}
img {
  object-fit: cover;
  height: auto;
}
::-webkit-scrollbar {
  width: 6px;
  background-color: #545353;
}
::-webkit-scrollbar-thumb {
  background-color: $color-ffffff;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
:focus {
  outline: none;
  outline: -webkit-focus-ring-color auto 0px;
}

::-moz-focus-inner {
  border: 0;
}
button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}
li {
  list-style-type: none;
}
[type="submit"],
button,
html [type="button"] {
  appearance: none;
  -webkit-appearance: none;
}
input [type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $color-131313 !important;
}

.form_input:-webkit-autofill,
.form_input:-webkit-autofill:hover,
.form_input:-webkit-autofill:focus,
.form_input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

table {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.error {
  // display: none;
  font-size: 1.125em;
  margin-top: 0.25em;
  font-weight: 600;
  color: red;
  position: absolute;
  &:first-letter {
    text-transform: uppercase;
  }
}

@media (min-width: 2560px) {
  body {
    font-size: 18px;
  }
  .my_container {
    max-width: 2226px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1920px) {
  body {
    font-size: 16px;
  }
  .my_container {
    max-width: 1640px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 1700px) {
  body {
    font-size: 13px;
  }
  .my_container {
    max-width: 1619px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1620px) {
  body {
    font-size: 13px;
  }
  .my_container {
    // max-width: 1480px;
    max-width: 1420px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1536px) {
  body {
    font-size: 11px;
  }
  .my_container {
    max-width: 1356px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}
@media (max-width: 1440px) {
  .my_container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 1366px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1194px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 1280px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1113px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1194px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1031px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1080px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 931px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 900px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 600px) {
  .my_container {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

.heading {
  color: #1c1c1c;
  font-size: 3.75em;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 1.33;
}

.desc {
  color: #1c1c1c;
  font-size: 1.625em;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 1.538;
  opacity: 0.6;
}

@media (max-width: 767px) {
  .heading {
    font-size: 2.8em;
    // line-height: 2.857;
    line-height: normal;
  }
  .desc {
    font-size: 1.6em;
    line-height: 1.625;
  }
}
